html {
    background-color: #f2f2f2;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", "Anton", sans-serif;
    font-size: 16px;
    color: #333;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: url("/arrow_pattern.svg");
    background-size: 100px 100px;
    height: 100%;
}

h1, h2, h3, h4, h5, h6, a, button {
    font-family: "Oswald", serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.redColor {
    color: #CA3632;
}

.bgRedColor {
    background-color: #CA3632;
    color: white;
}

.bgGreenColor {
    background-color: #60B47E;
    color: white;
}

.blueColor {
    color: #353B44;
}

.bgBlueColor {
    background-color: #353B44;
    color: white;
}

.bgGrayColor {
    background-color: #e1e1e1;
    /*color: black;*/
}

.lightGrayColor {
    color: #f3f3f3;
}

.grayColor {
    color: #e1e1e1;
}

.blackColor {
    color: #1b1819;
}

.btn-redcolor,
.btn-redcolor:disabled,
.btn-redcolor:hover,
.btn-redcolor:not(:disabled):not(:disabled):active {
    background-color: #CA3632;
    border-color: #CA3632;
}

.btn-bluecolor,
.btn-bluecolor:disabled,
.btn-bluecolor:hover,
.btn-bluecolor:not(:disabled):not(:disabled):active {
    background-color: #353B44;
    border-color: #353B44;
}

.btn-menu:disabled,
.btn-menu:hover,
.list-group-item-action:focus,
.btn-menu:not(:disabled):not(:disabled):active {
    background-color: #353B44;
    border-color: #353B44;
    color: white;
}

.btn-greencolor,
.btn-greencolor:disabled,
.btn-greencolor:hover,
.btn-greencolor:not(:disabled):not(:disabled):active {
    background-color: #60B47E;
    border-color: #60B47E;
}

.input-group-text {
    background-color: #353B44;
    border-color: #353B44;
    color: white;
}

.alert-footer {
    background-color: rgba(225, 225, 225, 0.2);
    border-color: rgba(225, 225, 225, 0.2);
}
